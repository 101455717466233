@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: #f2f3f8;
  margin: 0;
  font-family: "Poppins", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --ev-white--: #fff;
  --ev-gray--: #cfcfcf;
  --ev-lightblue--: #8b89a9;
  --ev-darkblue--: #090447;
  --ev-green--: #16b239;

  --chatlook-white--: #fff;
  --chatlook-lightblue--: #ededfc;
  --chatlook-darkblue--: #6f6af8;
  --chatlook-green--: #15cd2b;
  --chatlook-yellow--: #fff27d;
  --chatlook-sky--: #86c1f8;
  --chatlook-grey--: #f2f3f8;
}

/* ========================================= Login Page CSS ========================== */

.mainLoginPage {
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.loginDiv {
  border-radius: 16px;
  width: 440px;
  margin: auto;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important;
  overflow: hidden;
}

.loginPage {
  background-color: var(--chatlook-white--);
  padding: 40px;
}

.logoBar {
  height: 140px;
  background-color: var(--chatlook-darkblue--);
  padding: 20px;
}

/* ========================================= Navbar Page CSS ========================== */

.mainNavbar {
  outline: 4px solid #f1f1f100;
  padding: 10px 0 6px;
  cursor: pointer;
  transition: 0.3s;
}

.title-nav {
  color: #090447;
  font-size: 26px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  white-space: nowrap;
}

/* Nav Menu Toggle JS CSS */
.mobNav {
  margin-left: 75px;
}

/* Mobile Size */
/* @media screen and (max-width: 992px) {
  .webNav {
    margin-left: 75px;
  }

  .mobNav {
    margin-left: 220px;
  }
} */

/* ========================================= Sidebar Page CSS ========================== */

/* Side Menu Toggle JS CSS */

.sideBar.mobSidebar .sideBarLogo span {
  display: none;
}

.sideBar.mobSidebar .mainMenu li a > div > span,
.sideBar.mobSidebar .mainMenu > li > a > i,
.sideBar.mobSidebar .subMenu li a span {
  display: none;
}

.sideBar.mobSidebar .mainMenu li a {
  justify-content: start;
}

.sideBar.mobSidebar .mainMenu li a i {
  margin: 0;
}

.sideBar.mobSidebar .subMenu li a {
  padding-left: 22px;
}

/* End Side Menu Toggle JS CSS */

/* Mobile Size */
@media screen and (max-width: 992px) {
  /* .webSidebar {
    width: 75px;
  } */

  /* .sideBar .sideBarLogo span {
    display: none;
  } */

  /* .sideBar .mainMenu li a > div > span,
  .sideBar .mainMenu > li > a > i,
  .sideBar .subMenu li a span {
    display: none;
  } */

  /* .sideBar .mainMenu li a {
    justify-content: center;
  } */

  .sideBar .mainMenu li a i {
    margin: 0;
  }

  .sideBar .subMenu li a {
    padding-left: 20px;
  }

  /* ============ */

  .mobSidebar {
    width: 220px;
  }

  .sideBar.mobSidebar .sideBarLogo span {
    display: block;
  }

  .sideBar.mobSidebar .mainMenu li a > div > span,
  .sideBar.mobSidebar .mainMenu > li > a > i,
  .sideBar.mobSidebar .subMenu li a span {
    display: block;
  }

  .sideBar.mobSidebar .mainMenu li a > div {
    display: flex;
    align-items: center;
  }

  .sideBar.mobSidebar .mainMenu > li > a {
    justify-content: space-between;
    align-items: center;
  }

  .sideBar.mobSidebar .mainMenu li a i {
    margin-right: 10px;
  }

  .sideBar.mobSidebar .subMenu li a {
    padding-left: 30px;
  }
}

.rotate90 {
  transform: rotate(90deg);
  transition: 0.3s;
}

.mainSidebar {
  position: fixed;
  z-index: 11;
}

.sideBar {
  height: 100vh;
  background-color: var(--chatlook-darkblue--) !important;
  transition: 0.3s;
  overflow: hidden;
}

.sideBarLogo {
  padding: 9px;
}

.navigation {
  padding: 10px;
}

.mainMenu > li {
  padding-bottom: 5px;
}

.mainMenu li a {
  color: var(--ev-gray--);
  font-size: 13px;
  font-weight: 600;
  /* padding: 10px 20px; */
  border-radius: 6px;
  transition: 0.3s;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-bottom: 5px;
}

.mainMenu li > a:hover,
.mainMenu .activeMenu {
  background-color: #ffffff2f;
  color: var(--ev-white--);
}

.mainMenu li a i {
  margin-right: 10px;
  transition: 0.3s;
}

.content {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.5s ease-out;
}

.subMenu {
  overflow: hidden;
}

right .subMenu li a {
  justify-content: start;
  padding-left: 30px;
}

.subMenu li a i {
  font-size: 6px;
}

/* ================================== Admin CSS =================================== */

.mainAdmin {
  border-radius: 25px;
  transition: 0.3s;
}

.mobAdmin {
  margin-left: 85px;
}

/* Dash */
.totaldepo-main {
  padding: 15px 20px;
  background: var(--white, #fff);
  box-shadow: 0px 12px 24px 0px rgba(174, 172, 242, 0.05);
}
.box-img {
  width: 35px;
}

.totaldepo-img-sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.depomenu-btn span {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 12px 24px 0px rgba(174, 172, 242, 0.05);
}

.totaldepo-text h4 {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
}

.totaldepo-text p {
  font-size: 14px;
  font-weight: 500;
}

.pass-mainsec {
  padding: 22px 20px;
}

.pass-mainsec h2 {
  margin-bottom: 18px;
  font-size: 16px;
  font-weight: 500;
}

.profile-secmain {
  padding: 36px 20px;
}

.profile-detail h4 {
  font-size: 30px;
  font-weight: 500;
}

.profile-detail p {
  font-size: 16px;
  font-weight: 400;
}

.user-status {
  padding: 18px 22px;
  margin: auto;
  background: rgba(71, 62, 208, 0.07);
  margin-top: 40px;
}

.user-status p {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
}

.table-custome {
  border-radius: 8px 8px 0 0;
  box-shadow: 0px 12px 24px 0px rgba(174, 172, 242, 0.05);
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 0px;
  border-radius: 50%;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: #f1f1f1; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(231, 231, 231);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8b89a9;
}

.main::-webkit-scrollbar {
  width: 4px;
}
/* .table-custome table {
  border-collapse: separate;
  border-spacing: 0 10px;
} */

.table-custome thead tr {
  border-bottom: 1px solid #f1f1f1;
}

.table-custome tbody tr {
  border-radius: 10px;
  border-bottom: 1px solid #f1f1f1;
}

.table-custome tbody .tr {
  outline: none !important;
}

.table-custome tbody tr td {
  padding: 10px 10px 10px 20px;
}

.table-custome thead tr th:nth-child(1) {
  padding-left: 20px;
}

.table-custome tbody tr td:nth-child(1) {
  padding-left: 12px;
}

/* .table-custome tbody tr:hover {
  box-shadow: 0px 10px 40px 0px rgba(86, 89, 146, 0.1);
} */

.table-custome thead tr th {
  padding: 10px 20px 10px 20px;
}

th {
  position: relative;
  text-align: left;
}

th::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  height: 20px;
  width: 1px;
  background-color: #8b89a92d;
}
th:last-child::after {
  display: none;
}

.table-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.table-heading {
  margin: 6px 0 10px;
  font-size: 20px;
  font-weight: 600;
}

.apply-clear {
  margin-top: 34px;
}

.appl-btn {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  padding: 10px 18px;
  color: #fff;
  font-size: 16px;
  border: 1px solid var(--chatlook-darkblue--);
}
.appl-btn:hover {
  transition: 0.2s;
  border: 1px solid var(--chatlook-darkblue--);
  background-color: var(--chatlook-white--);
  color: var(--chatlook-darkblue--)
}

.login-main {
  padding: 44px 34px;
}

.welc-text h4 {
  color: #000;
  font-size: 22px;
  font-weight: 600;
}

.welc-text p {
  color: #8b8d97;
  font-size: 14px;
  font-weight: 400;
}

.flatpickr-weekdays {
  background-color: var(--chatlook-darkblue--) !important;
}
span.flatpickr-weekday {
  background-color: var(--chatlook-darkblue--) !important;
  color: var(--ev-white--) !important;
}
.flatpickr-months .flatpickr-month {
  background-color: var(--chatlook-darkblue--) !important;
  color: var(--ev-white--) !important;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  background-color: var(--chatlook-darkblue--) !important;
  color: var(--ev-white--) !important;
}
.flatpickr-day.selected {
  background-color: var(--chatlook-darkblue--) !important;
  color: var(--ev-white--) !important;
  border-color: var(--chatlook-darkblue--) !important;
}
.flatpickr-calendar.arrowTop:after {
  border-bottom-color: var(--chatlook-darkblue--) !important;
}
.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
  box-shadow: -10px 0 0 var(--chatlook-darkblue--) !important;
}
.flatpickr-day.selected,
.flatpickr-day.startRange {
  background: var(--chatlook-darkblue--) !important;
  border-color: var(--chatlook-darkblue--) !important;
}
.pagination-ul ul {
  align-items: center;
}
/* Mobile Size */
@media screen and (max-width: 992px) {
  .mainAdmin {
    position: relative;
    z-index: 1;
    margin-left: 0;
  }
  .mainAdmin {
    margin-right: 10px;
  }
}

.flatpickr-day.endRange {
  background-color: var(--chatlook-darkblue--) !important;
  color: var(--ev-white--) !important;
  border-color: var(--chatlook-darkblue--) !important;
}

.drag-file-area {
  border: 1px dashed #eaecf0;
}

.drag-file-area .label .browse-files-text {
  cursor: pointer;
}
.browse-files div {
  position: relative;
  top: -25px;
}
.gif-success {
  mix-blend-mode: color-burn;
}
.select-img select {
  background-color: #fff;
  background-image: url(./assets/images/downarrow-light.svg);
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
}

